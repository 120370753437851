export enum PageRoute {
  HOME = '/',
  LOGIN = '/login',
  TEST = '/test',
  USER = '/user',
  NOT_FOUND = '/404',
  FORGOT_PASSWORD = '/forgot-password',
  RESET_PASSWORD = '/reset-password',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  CHANGE_PASSWORD = '/change-password',
  TENANT_LIST = '/tenant',
  TENANT_DETAIL = '/tenant/:id',
  ADD_TENNANT = '/add-tenant',
  PROFILE = '/profile',
  CONFIG_BILLING = '/config-billing',
  OCR_LIST = '/ocr-list',
  TEMPLATE_LIST = '/template-list',
  TEMPLATE_ADD = '/template-list/add',
  TEMPLATE = '/template',
  TEMPLATE_DETAIL = '/template/detail',
  RequestOCR = '/request-ocr',
  Usage = '/usage',
  Invoice = '/invoice',
}
