import axios, {AxiosResponse} from 'axios';
import jwtDecode from 'jwt-decode';
import {ENDPOINT} from 'constants/endpoint';
// import { STATUS_CODE } from 'constants/status';
import {Service} from 'typedi';
import {storageKeys} from 'constants/storage-keys';
import {API_HOST} from 'environment/environment';
// import { message } from 'antd';

import {DTO, ResponseDTO} from 'shared/dto/base.dto';
import {STATUS_CODE} from 'constants/status';
import i18n from 'locales/i18n';

export interface ITokenInfo {
  exp: number;
  iat: number;
  role: number;
  userId: number;
  username: string;
}

@Service()
export class HttpService {
  public access_token: string | undefined;

  constructor() {
    this.access_token =
      localStorage.getItem(storageKeys.USER_ACCESS_TOKEN) || undefined;
    axios.interceptors.response.use(
      (response: AxiosResponse<ResponseDTO>) => {
        return response;
      },
      (error) => {
        if (error && error.response && error.response.data) {
          if (error?.response?.data?.statusCode === 401) {
            localStorage.clear();
            this.access_token = undefined;
          }

          return error.response;
        }
        return Promise.reject(error.response);
      },
    );
  }

  public getTokenInfo(): {exp: number} | undefined {
    return this.access_token ? jwtDecode(this.access_token) : undefined;
  }

  public async request<T extends DTO>(dto: T): Promise<ResponseDTO> {
    try {
      const response = await axios({
        method: dto.method,
        headers: {
          Authorization: this.access_token ? 'Bearer ' + this.access_token : '',
          'X-Frame-Options': 'DENY', // Prevent Clickjacking
          'Content-Security-Policy': "frame-ancestors 'self';",
          lang: `${i18n.resolvedLanguage}`,
          ...dto.headers,
        },
        baseURL: API_HOST + ENDPOINT.BASE_URL,
        url: this.replaceURLToParamURL(dto.url, dto.param || {}),
        data: dto.body,
        params: dto.query,
        responseType: dto.responseType,
      });
      if (response.status !== 200) {
        return {
          data: response?.data,
          msgSts: response?.data?.msgSts || {
            code: STATUS_CODE.FAILED,
            message: 'Get data fail',
          },
        };
      } else {
        return {
          data: response.data || response.data,
          msgSts: response.data.msgSts || {
            code: STATUS_CODE.SUCCESS,
            message: 'Get data success',
          },
        };
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (errorResponse: any) {
      return {
        data: errorResponse?.data?.data,
        msgSts: errorResponse?.data?.msgSts || {
          code: STATUS_CODE.FAILED,
          message: 'Get data fail',
        },
      };
    }
  }

  private replaceURLToParamURL = (url: string, param: object): string => {
    let newUrl = url;
    Object.entries(param).forEach(([key, value]) => {
      newUrl = newUrl.replace(':' + key.toString(), value.toString());
    });
    return newUrl;
  };
}
