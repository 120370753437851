import React from 'react';
import clsx from 'clsx';
import styles from './Loader.module.scss';
import {Spin} from 'antd';

type PropsType = {
  mode?: 'normal' | 'reverse-color';
};

export const Loader: React.FC<PropsType> = ({mode}) => {
  return (
    <div className={clsx(styles.wrapper, mode)}>
      <Spin />
    </div>
  );
};
