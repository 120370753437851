const en = {
  Login: {
    title: 'Login',
    forgotPassword: 'Forgot password?',
    rememberMe: 'Remember me',
  },
  ChangePassWord: {
    currentPassword: 'Current password',
    newPassword: 'New password',
    confirmPassword: 'Confirm new passwords',
  },
  Placeholder: {
    email: 'Email',
    password: 'Password',
    forgotpass: 'Enter mail address',
    placeholderPassword: 'Password',
    inputConfirmPass: 'Confirm new password',
    inputNewPass: 'Enter new password',
  },
  title: {
    titleAdmin: 'Admin',
    titleHome: 'Home',
    titleNewPassword: 'New Password',
    titleForgotPassword: 'Forgot Password',
    titleBilling: 'Billing',
    titleInvoice: 'Invoice',
  },
  Menu: {
    menu: 'Menu',
    setting: 'Setting',
    // new menu
    requestOCR: 'Request OCR',
    listOCR: 'OCR List',
    manageTemplate: 'Manage Template',
    // end new menu
    ocr: 'OCR',
    billing: 'Billing',
    usage: 'Usage',
    invoice: 'Invoice',
    configBilling: 'Billing',
    tenants: 'Tenants',
    config: 'Config',
    changePassword: 'Change Password',
    account: 'My Profile',
    logout: 'Logout',
    language: 'Language',
    help: 'Help',
    menuBillingTenant: {
      billing: 'Billing',
      usage: 'Usage',
      invoice: 'Invoice',
    },
  },
  Button: {
    btnSignIn: 'Sign in',
  },
  btnSend: 'Send',
  btnSave: 'Save',
  btnCancel: 'Cancel',
  btnApply: 'Apply',
  btnExport: 'Export',
  btnReset: 'Reset',
  btnDownload: 'Download',
  btnView: 'View',
  btnDelete: 'Delete',
  btnDeactivate: 'Deactivated',
  btnSubmit: 'Submit',
  btnActive: 'Active',
  btnConfirm: 'Confirm',
  btnEdit: 'Edit',
  btnBack: 'Back',
  btnBacksignIn: 'Back to Sign-in',
  btnGenerateExcel: 'Generate Excel',
  btnClickUpload: 'Browse to upload',
  txtFormatEmail: 'Incorrect mail format!',
  txtFormatPassword:
    '8 digits at least, case sensitive, non-alphanumeric symbol',
  txtCompanyInfo: 'General Information',
  txtBillinginformation: 'Billing information',
  txtDescription: 'Description',
  txtUpdateSettingSuccess: 'Update setting success',
  txtUpdateSettingFail: 'Update setting fail',
  txtBillDate: 'Bill Date',
  txtLastPaymentDate: 'Last Payment Date',
  txtClearFilter: 'Clear Filter',
  txtPagesPerBlock: 'Pages per Block',
  txtConfirm: 'Confirm',
  txtSearch: 'Search',
  txtCategory: 'Category',
  txtUpdateDate: 'Update Date',
  txtUploadDate: 'Upload Date',
  txtOwner: 'Type',
  txtName: 'Name',
  txtTemplateList: 'Template List',
  txtPerPage: 'page',
  txtOf: 'of',
  txtItems: 'items',
  txtDeleteTenant: 'Are you sure to delete Tenant',
  txtTitle: 'Title',
  txtDeleteTemplate: 'Are you sure to delete Template',
  txtRequireUppercase: ' Capital letter(A-Z)',
  txtRequireLowercase: ' Lowercase letter(a-z)',
  txtRequireSpecialCharacter: ' Special characters( !@#$%^&*)',
  txtRequireNumber: ' Numbers(0-9)',
  txtRequirePasswordLength: ' Password must be at least 8 characters.',
  txtHeadingValidate: 'Password must contain',
  passwordConfirmation: 'The password confirmation does not match',
  txtInputEmailRequired: 'This is required field!',
  txtDelete: 'Are you sure to permanently delete this item?',
  txtFotmatPhone: 'Please enter your phone number correctly',
  txtPassword: 'Incorrect password format',
  txtTotalPackageUsed: 'Total Package Used',
  txtTotalBlockUsed: 'Total Block Used',
  txtTotalPageUsed: 'Total Page Used',
  txtDuplicate: 'Duplicate',
  txtDetail: 'Details',
  txtResetPayment: 'Are you sure you want to reset payment day?',
  requiredField: 'This is required field!',
  txtCreate: 'Are you sure you want to create this?',
  txtSubmissionConfirmationRequired: 'Are you sure to save these changes?',
  txtTotalTenants: 'Total Tenants',
  txtFromMonth: 'From month',
  txtToMonth: 'To month',
  txtMyProfile: 'My Profile',
  txtAllTenant: 'All Tenants',
  txtEdit: 'Edit',
  txtAddTenant: 'Add Tenant',
  txtBilling: 'Billing',
  txtBillingDecription: 'This information is applied to all Tenants.',
  txtPages: 'Page',
  txtPackageRegisteredCurrentMonth: 'Package Registered - Current Month',
  txtPackageRegisteredNextMonth: 'Package Registered - Next Month',
  txtBlockRegisteredCurrentMonth: 'Block Registered - Current Month',
  txtBlockRegisteredNextMonth: 'Block Registered - Next Month',
  txtCurrentValue: 'Current Value',
  txtNextValue: 'Current Value',
  txtNewValue: 'New Value',
  descriptionCurrentMonth:
    'New value will be applied for the current month. Please ensure that new value accurately reflects the desired changes. ',
  descriptionNextMonth:
    'New value will be applied for the next month onwards. Please ensure that new value accurately reflects the desired changes.',
  txtPackageRegistered: 'Package Registered',
  txtBlockRegistered: 'Block Registered',
  txtTotalPackageRegistered: 'Total Package Registered',
  txtTotalBlockRegistered: 'Total Block Registered',
  txtBlocksPerPackage: 'Blocks per Package',
  txtConfirmPackageRegistered: 'Confirm Package Registered - Current Month',
  txtConfirmPackageRegisteredNext: 'Confirm Package Registered - Next Month',
  txtConfirmBlockRegistered: 'Confirm Block Registered - Current Month',
  txtConfirmBlockRegisteredNext: 'Confirm Block Registered - Next Month',
  txtFailFormat: 'Fail format',
  txtCurrentlyLimit:
    'Your request is currently exceeding limit! Please contact admin to adjust limit.',
  txtRemainingPageLimit: 'Remaining page limit',
  txtValuePackageRegistered: 'New value must be greater than current value!',
  txtGenerateExcelUsage: 'Generate Excel',
  txtAccessExpired:
    'This function is no longer available. Please contact Admin for assistance or renewal.',
  accessExpired: 'Access Expired',
  txtManyPage: 'Error! Too many pages detected.',
  txtCreateNewRequest: 'Create new request',
  txtGoOCRList: 'Go to OCR list',
  txtlimitExceed: 'Error! Limit exceeded, available page limit = ',
  txtWarning: 'Warning',
  txtFilesInvalid: 'Files with invalid format or size detected',
  uploadFileMax: 'Up to 10 files can be chosen per uploading',
  txtSent: 'Sent',
  txtDescriptionTemplate: 'Description',
  txtActionTemplate: 'Action',
  txtDescriptionToolTip:
    'Enter instructions here to guide the OCR in extracting values from the uploaded document in accordance to Field name you defines.',
  txtDescriptionToolTip1: 'Be specific and detailed.',
  txtForExample: 'For example:',
  txtForExample1: 'Field Name: Customer Name',
  txtForExample2:
    'Description: Customer name, the mentioned company at the bottom of document.',
  txtDescriptionToolTip3:
    'Remember that clear and precise instructions can improve the accuracy of the OCR results',
  // Tenant
  Tenant: {
    searchTenant: 'Search',
    labelTenant: 'Tenant',
    labelStatus: 'Status',
    labelBlockLimit: 'Block Limit',
    labelMin: 'Min',
    labelMax: 'Max',
    labelOnboardDate: 'Onboard Date',
    txtSelectDate: 'Select date',
    deleteSuccess: 'Delete tenant success',
    deleteFail: 'Delete tenant fail',
    activeSuccess: 'Change status success',
    activeFail: 'Change status fail',
    btnAddTenant: 'Add Tenant',
    txtDetailTenant: 'Detail Tenant',
    updateTenantSuccess: 'Update Tenant Success',
    updateTenantFail: 'Update Tenant Fail',
    createTenantSuccess: 'Create Tenant Success',
    createTenantFail: 'Create Tenant Fail',
    labelUserInformation: 'User Information',
    labelGeneralInformation: 'General Information',
    labelSubscriptionInformation: 'Billing Information',
    getTenantSuccess: 'Get Tenant Success',
    getTenantFail: 'Get Tenant Fail',
    txtAction: 'Action',
  },
  Template: {
    txtTemplate: 'Template',
    txtDetailTemplate: 'Detail Template',
    templateName: 'Template Name',
    category: 'Category',
    description: 'Description',
    fieldSet: 'Field Set',
    standalone: 'Standalone',
    table: 'Table',
    create: 'Create',
    txtCreateTemplate: 'Create Template',
    txtEditTemplate: 'Edit Template',
    createTemplateSuccess: 'Create Template Success',
    createTemplateFail: 'Create Template Fail',
    txtDocuments: 'Documents',
    descriptionFile: 'Accepted format: PDF / JPEG / JPG / PNG',
    txtBtnUpload: 'Browse to upload',
    txtAddNewTemplate: 'Add New Template',
    txtStandalone: 'Standalone',
    txtTable: 'Table',
    txtAddRow: 'Add Row',
    Table: {
      templateID: 'Template ID',
      createdDate: 'Created Date',
      txtDescription: 'Description',
      txtAction: 'Action',
    },
  },
  Invoice: {
    billMonth: 'Bill Month',
    confirmPayment: 'Confirm Payment',
    confirmPaymentSuccess: 'Confirm Payment Success',
    confirmPaymentFail: 'Confirm Payment Fail',
    exportInvoice: 'Export',
    Table: {
      invoiceID: 'Invoice ID',
      tenantID: 'Tenant ID',
      tenants: 'Tenants',
      billMonth: 'Bill Month',
      month: 'Month',
      noOfRequest: 'No of request',
      pageUsed: 'Page Used',
      blockUsed: 'Block Used',
      paymentStatus: 'Payment Status',
      paymentDate: 'Payment Date',
      markAsPaid: 'Mark as Paid',
      action: 'Action',
      usageStatus: 'Usage Status',
      ocrID: 'OCR ID',
      situation: 'Status',
      noOfPage: 'No of Page',
      completedDate: 'Completed Date',
      ocrRate: 'OCR Rate',
      uploadDate: 'Upload Date',
      packageUsed: 'Package Used',
      packageRegistered: 'Package Registered',
      blockRegistered: 'Block Registered',
      actionInvoice: 'Action',
    },
    statusInvoice: {
      processing: 'Processing',
      completed: 'Completed',
      failed: 'Failed',
    },
  },
  OCR: {
    ocrTitle: 'Create OCR Request',
    btnOcrRequest: 'Create OCR Request',
    warningConfirmation: 'Warning Confirmation Required?',
    descriptionConfir: 'Are you sure you want to submit this?',
    createOcrSuccess: 'Create OCR Success',
    createOcrFail: 'Create OCR Fail',
  },
  AddTenant: {
    emailAddress: 'Email Address',
    placeholderPassword:
      '8 digits at least, case sensitive, non-alphanumeric symbol',
    companyName: 'Company Name',
    placeholderCompanyName: 'Search is Company Name',
    placeholderEmailAddress: 'Enter email address',
    tenantDescriptions: 'Tenant Descriptions',
    placeholderTenantDescriptions: 'Enter Descriptions',
    inputCompanyName: 'Search is Company Name',
    inputAddress: 'Enter Address',
    contactEmail: 'Contact Email',
    placeholderContactEmail: 'Enter Contact email address',
    address: 'Address',
    placeholderAddress: 'Enter Address',
    contactName: 'Contact Name',
    placeholderContactName: 'Enter Contact name',
    phoneNumber: 'Phone number',
    placeholderPhoneNumber: 'Enter Phone number',
    blockLimit: 'Block Limit',
    placeholderBlockLimit: 'Eg. 10',
    expiredDate: 'Expired Date',
    tenantStatus: 'Tenant Status',
    inputRequired: 'This is required field!',
  },
  OCRList: {
    txtSearch: 'Search',
    txtStatus: 'Status',
    exportExcelFail: 'Export excel fail',
  },
  // end new

  validateForm: {
    msgRequirePassword: 'This is required field!',
    msgPassError:
      'Password must have at least 8 characters including lowercase letters, uppercase letters, numbers and special characters!',
    msgNotSamePass: 'New password does not match!',
    msgIsSameOldPass:
      'The new password cannot be the same as the old password!',
  },
  txtInputNewPasswordRequired: 'This is required field!',
  DocumentManagement: {
    txtPaginationContent: 'Display {{startRange}} - {{endRange}}/{{total}}',
  },
  RuleMessage: {
    inputPassword: 'Please input password',
    inputEmail: 'Please input email',
  },
  Table: {
    tenantID: 'Tenant ID',
    tenant: 'Tenant',
    email: 'Email',
    blockLimit: 'Block Limit',
    onboardDate: 'Onboard Date',
    updateDate: 'Updated Date',
    tenantStatus: 'Tenant Status',
    action: 'Action',
  },
  Label: {
    labelEmailAddress: 'Email Address',
  },
  MessageError: {
    msgSentRequestFail: 'Email address does not exist.',
    loginFail: 'Your email address or password is incorrect',
    msgSentChangePasswordFail: 'Current password is incorrect',
    passwordSamePassOld: ' New password does not match!',
    passwordNotMatch: 'The passwords you just entered do not match',
    valiPassword:
      'Password must have at least 8 characters including lowercase letters, uppercase letters, numbers, and special characters!',
    msgUpdateProfileFail: 'Update profile fail',
    txtFail: 'Failed!',
    passIncorrect: ' Your user name or password is incorrect',
    currentCorrect: 'Current password is not correct!',
    emailExistsTenant: 'Email address already exists in the system!',
    companyExist: 'Company Name already exists in the system!',
    emailDoesNot: 'Email address does not exist in the system!',
  },
  MessageSuccess: {
    msgSentRequestSuccess:
      'Reset password link is sent to your email address. Please check your inbox (or spam folder) to reset your password.',
    msgUpdateProfileSuccess: 'Update profile success',
    passwordResetSuccess: 'Password is reset successfully!',
    txtSuccess: 'Successful!',
  },
  Status: {
    Usage: {
      all: 'All',
      normal: 'Normal',
      closeToLimit: 'Close to limit',
      fullCapacity: 'Full capacity',
    },
    Template: {
      all: 'All',
      custom: 'Custom',
      standard: 'Standard',
    },
    OCRList: {
      all: 'All',
      processing: 'Processing',
      completed: 'Completed',
      failed: 'Failed',
    },
    Invoice: {
      all: 'All',
      pending: 'Pending',
      paid: 'Paid',
      overdue: 'Overdue',
    },
    Tenant: {
      all: 'All',
      active: 'Active',
      deactive: 'Deactivated',
    },
  },
};

export default en;
