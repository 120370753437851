import React, {useEffect, useState} from 'react';
import Container from 'typedi';
import {useTranslation} from 'react-i18next';
import {Button, Layout, Menu, MenuProps} from 'antd';
import {Link, Outlet, useLocation} from 'react-router-dom';
import {i18nKey} from 'locales/i18n';
import Logo from 'assets/images/logo.png';
import ICCPU from 'assets/icons/ic_cpu.svg';
import {PageRoute} from 'constants/route';
import {LogoutDTO} from 'shared/dto/login.dto';
import icLogout from 'assets/icons/ic_logout.svg';
import {AuthService} from 'services/auth.service';
import styles from './MainLayout.module.scss';

const {Content, Sider} = Layout;
type MenuItem = Required<MenuProps>['items'][number];
function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group',
  onClick?: () => void,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any {
  return {
    key,
    icon,
    children,
    label,
    type,
    onClick,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } as any;
}

const MainLayout: React.FC = () => {
  const location = useLocation();
  const authService = Container.get(AuthService);
  const {t} = useTranslation();
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const [openKeys, setOpenKeys] = useState<string[]>([]);
  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    setSelectedKeys([location.pathname]);
  }, [location.pathname]);

  const onCollapse = () => {
    setCollapsed((curIsCollapsed) => !curIsCollapsed);
  };

  const items: MenuProps['items'] = [
    getItem(
      <div className={styles.titleGroup}>{t(i18nKey.Menu.menu)}</div>,
      'grp2',
      null,
      undefined,
      'group',
    ),
    getItem(t(i18nKey.Menu.ocr), 'sub4', <img src={ICCPU} />, [
      getItem(
        <Link to={PageRoute.RequestOCR}>{t(i18nKey.Menu.requestOCR)}</Link>,
        PageRoute.RequestOCR,
      ),
      getItem(
        <Link to={PageRoute.OCR_LIST}>{t(i18nKey.Menu.listOCR)}</Link>,
        PageRoute.OCR_LIST,
      ),
      getItem(
        <Link to={PageRoute.TEMPLATE_LIST}>
          {t(i18nKey.Menu.manageTemplate)}
        </Link>,
        PageRoute.TEMPLATE_LIST,
      ),
    ]),
  ];

  const handleOpenChange = (keys: string[]) => {
    setOpenKeys(keys);
  };

  const logOut = () => {
    try {
      authService.logOut(new LogoutDTO());
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Layout className={styles.mainLayout}>
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        className={styles.wrapperSider}
        onCollapse={onCollapse}
        width={250}
      >
        <div className={styles.wrapperLogo}>
          <img className={styles.logo}  src={Logo} alt="Logo" />
        </div>
        <Menu
          className={styles.mainMenu}
          selectedKeys={selectedKeys}
          openKeys={openKeys}
          mode="inline"
          items={items}
          style={{backgroundColor: '#175CD3'}}
          onSelect={({key}) => setSelectedKeys([key])}
          onOpenChange={handleOpenChange}
        />
        <div className={styles.wrapperButton}>
          <Button type="link" onClick={logOut} style={{marginBottom: 16}}>
            <img src={icLogout} alt="Logout" />{' '}
            <div style={{display: collapsed ? 'none' : 'block'}}>
              {t(i18nKey.Menu.logout)}
            </div>
          </Button>
        </div>
      </Sider>
      <Content className={styles.wrapperContent}>
        <Outlet />
      </Content>
    </Layout>
  );
};

export default MainLayout;
