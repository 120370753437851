export enum ENDPOINT {
  BASE_URL = '',
  LOGIN = '/auth/login',
  USER_ME = '/user/me',
  FORGOT_PASSWORD = '/forgot-password',
  RESET_PASS = '/forgot-password/reset',
  LOGOUT = '/auth/logout',
  CHANGE_PASS = '/auth/change_password',
  TENANT = '/tenant',
  DELETE_TENANT = '/tenant/:id',
  CREATE_TENANT = '/tenant/with-user',
  SETTING = '/settings',
  TENANT_ME = '/tenant/my-tenant',
  TEMPLATE_LIST = '/template',
  EXPORT_DETAIL_ORC = '/request/:id/export',
  DETAIL_TEMPLATE = '/template/:id',
  REQUEST_OCR = '/request',
  EXPORT_EXCEL = '/request/export',
  USAGE = '/usage',
  USAGE_CURRENT = '/usage/current',
  INVOICE = '/invoice',
  INVOICE_DETAIL = '/invoice/:id',
  CONFIRM_PAID = 'invoice/:id/payment',
  INVOICE_EXPORT_DETAIL = '/invoice/:id/export',
  EXPORT_USAGE = '/usage/export',
  GET_ALL_TENANT = '/tenant/compact',
  UPDATE_STATUS_OCR_LIST = '/request/:id/download-status',
  GET_ALL_TEMPLATE = '/template/compact',
}
