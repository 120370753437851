import React from 'react';
import ReactDOM from 'react-dom/client';
import 'antd/dist/reset.css';
import 'reflect-metadata';
import './index.scss';
import App from './App';
import {ConfigProvider} from 'antd';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <ConfigProvider theme={{token: {colorPrimary: '#93363E'}}}>
      <App />
    </ConfigProvider>
  </React.StrictMode>,
);
