const jp = {
  Login: {
    title: 'ログイン',
    forgotPassword: 'パスワードを忘れた場合?',
    rememberMe: 'ログイン情報を記憶',
  },
  Placeholder: {
    email: 'メールアドレス',
    password: 'パスワード',
    forgotpass: 'メールアドレスを入力してください',
    placeholderPassword: 'パスワード',
    inputConfirmPass: '新しいパスワードを確認',
    inputNewPass: '新しいパスワードを入力してください',
  },
  Menu: {
    menu: 'メニュー',
    setting: '設定',
    // new menu
    requestOCR: 'OCRの作成',
    listOCR: 'OCR結果一覧',
    manageTemplate: 'テンプレートの管理',
    // end new menu
    ocr: 'OCR処理',
    billing: 'テナント詳細・処理',
    usage: 'テナント状況一覧',
    invoice: '請求書作成・処理',
    configBilling: '請求状況',
    tenants: 'テナント一覧',
    config: 'テナント請求情報',
    changePassword: 'パスワードを変更する',
    account: 'アカウント',
    logout: 'ログアウト',
    language: '言語',
    help: 'ヘルプ',
    menuBillingTenant: {
      billing: '処理管理',
      usage: '使用状況一覧（月毎）',
      invoice: '支払い状況',
    },
  },
  Button: {
    btnSignIn: 'サインイン',
  },
  btnSend: '送信',
  btnSave: '保存',
  btnCancel: 'キャンセル',
  btnApply: '適用する',
  btnExport: 'エクスポート',
  btnReset: 'リセット',
  btnBack: '戻る',
  btnDownload: 'ダウンロード',
  btnSubmit: '送信',
  btnView: '表示',
  btnDelete: '削除',
  btnDeactivate: '無効化',
  btnActive: 'アクティブ',
  btnEdit: '編集',
  btnConfirm: '確認',
  btnBacksignIn: 'サインインに戻る',
  btnGenerateExcel: 'OCR一覧作成<br>（Excelシート作成） ',
  btnClickUpload: 'アップロード',
  txtFormatEmail: 'メールの形式が正しくありません!',
  txtFormatPassword:
    '英数字以外の記号、半角英小文字大文字数字をそれぞれ1種類以上含む8文字以上で入力してください',
  txtCompanyInfo: '一般情報',
  txtBillinginformation: 'ブロック管理（ユーザー請求）',
  txtDescription: '記述',
  txtUpdateSettingSuccess: 'Update setting success',
  txtUpdateSettingFail: 'Update setting fail',
  txtBillDate: '請求日',
  txtLastPaymentDate: '最終支払日',
  txtClearFilter: 'クリアフィルター',
  txtPagesPerBlock: 'ブロックあたりのページ数',
  txtUpdateDate: 'アップロード日',
  txtUploadDate: '更新日',
  txtConfirm: '確認',
  txtSearch: '検索',
  txtCategory: 'カテゴリー',
  txtOwner: 'タイプ',
  txtPerPage: 'ページ',
  txtDeleteTenant: 'Are you sure to delete Tenant',
  txtTitle: 'タイトル',
  txtName: '名前',
  txtRequireUppercase: ' 大文字 (A ～ Z)',
  txtRequireLowercase: ' 小文字 (a ～ z)',
  txtRequireSpecialCharacter: ' 特殊文字 (!@#$%^&*)',
  txtRequireNumber: ' 数字 (0-9)',
  txtRequirePasswordLength: ' 少なくとも 8 文字',
  txtHeadingValidate: 'パスワードには以下を含める必要があります',
  txtDeleteTemplate: 'Are you sure to delete Template',
  passwordConfirmation: '新しいパスワードが一致しません!',
  txtInputEmailRequired: 'この情報の入力が必要です！',
  txtDelete: 'このアイテムを完全に削除してもよろしいですか?',
  txtFotmatPhone: '電話番号を正しく入力してください',
  txtPassword: 'パスワードの形式が正しくありません',
  txtTotalPackageUsed: '使用パッケージ合計',
  txtTotalBlockUsed: '使用されたブロックの合計',
  txtTotalPageUsed: '使用されたページの合計',
  txtTemplateList: 'テンプレート一覧',
  txtDuplicate: '重複',
  txtDetail: '詳細',
  txtResetPayment: '支払日をリセットしてもよろしいですか?',
  requiredField: 'この情報の入力が必要です！',
  txtCreate: 'これを作成してもよろしいですか?',
  txtSubmissionConfirmationRequired: '提出確認は必要ですか?',
  txtTotalTenants: 'テナント数',
  txtFromMonth: '月から',
  txtToMonth: '月まで',
  txtOf: 'の',
  txtItems: '項目',
  txtMyProfile: '私のプロフィール',
  txtAllTenant: 'テナント状況・入力・編集',
  txtEdit: '編集',
  txtAddTenant: 'テナントを追加',
  txtBilling: '請求',
  txtBillingDecription: 'この情報はすべてのテナントに適用されます',
  txtPages: 'ページ',
  txtPackageRegisteredCurrentMonth: '登録されたパッケージ －現在の月',
  txtPackageRegisteredNextMonth: '登録されたパッケージ －次の月',
  txtBlockRegisteredCurrentMonth: '登録されたブロック －現在の月',
  txtBlockRegisteredNextMonth: '登録されたブロック －次の月',
  txtCurrentValue: '現在の価値',
  txtNextValue: '現在の価値',
  txtNewValue: '新しい価値',
  descriptionCurrentMonth:
    '新しい価値は現在の月に適用されます。 新しい価値が希望の変更を正確に反映していることを確認してください。',
  descriptionNextMonth:
    '新しい価値は次の月以降適用されます。 新しい価値が希望の変更を正確に反映していることを確認してください。',
  txtPackageRegistered: '登録されたパッケージ',
  txtBlockRegistered: '登録したブロック数',
  txtTotalPackageRegistered: '登録されたパッケージの合計',
  txtTotalBlockRegistered: '登録したブロック合計',
  txtBlocksPerPackage: 'パッケージあたりのブロック数',
  txtConfirmPackageRegistered: '登録されたパッケージの確認　－現在の月',
  txtConfirmPackageRegisteredNext: '登録されたパッケージの確認　－次の月',
  txtConfirmBlockRegistered: '登録されたブロックの確認　－現在の月',
  txtConfirmBlockRegisteredNext: '登録されたブロックの確認　－次の月',
  txtFailFormat: 'Fail format',
  txtCurrentlyLimit:
    '現在リクエストは制限を超えております。この問題を解決する為には、管理者へご連絡してください。',
  txtRemainingPageLimit: '残りページ数',
  txtValuePackageRegistered:
    '新しい価値は現在の価値より大きくなければなりません。',
  txtGenerateExcelUsage: '使用状況作成（Excelシート作成） ',
  txtAccessExpired:
    'アクセスの有効期限切れサポートまたは更新のため、管理者にお問い合わせください。',
  accessExpired: 'Access Expired',
  txtManyPage: 'エラー！ページが多すぎます。',
  txtCreateNewRequest: '新しいリクエストを作成',
  txtGoOCRList: 'OCR結果一覧へ',
  txtlimitExceed: 'Error! Limit exceeded, available page limit = ',
  txtWarning: '警告',
  txtFilesInvalid: '無効なファイル形式またはファイルサイズが検出されたファイル',
  uploadFileMax: '1アップロードあたり最大10ファイルを選択できます。',
  txtSent: '送信済み',
  txtDescriptionTemplate: '読み取り領域指示',
  txtActionTemplate: '編集',
  txtDescriptionToolTip:
    '定義したフィールド名に従ってアップロードされたドキュメントから値を抽出するOCR をガイドする為、ここに指示を入力してください。',
  txtDescriptionToolTip1: '具体的かつ詳細に記載してください。',
  txtForExample: '例えば：',
  txtForExample1: '名前: Customer Name',
  txtForExample2:
    '読み取り領域指示: The mentioned company at the bottom of document.',
  txtDescriptionToolTip3:
    '明確かつ正確な指示により、OCR 結果の精度が向上することに注意してください。',
  // Tenant
  Tenant: {
    searchTenant: '検索',
    labelTenant: 'テナント',
    labelStatus: '状態',
    labelBlockLimit: 'ブロック制限',
    labelMin: '最小',
    labelMax: '最大',
    labelOnboardDate: '登録日付',
    txtSelectDate: '日付を選択する',
    txtDetailTenant: 'テナント詳細',
    deleteSuccess: 'Delete tenant success',
    deleteFail: 'Delete tenant fail',
    activeSuccess: 'Change status success',
    activeFail: 'Change status fail',
    btnAddTenant: 'テナントを追加',
    updateTenantSuccess: 'Update Tenant Success',
    updateTenantFail: 'Update Tenant Fail',
    createTenantSuccess: 'Create Tenant Success',
    createTenantFail: 'Create Tenant Fail',
    labelUserInformation: 'ユーザー情報',
    labelGeneralInformation: '一般情報',
    labelSubscriptionInformation: 'パッケージ管理（ユーザー請求）',
    getTenantSuccess: 'Get Tenant Success',
    getTenantFail: 'Get Tenant Fail',
    txtAction: '入力・編集',
  },
  Template: {
    txtTemplate: 'テンプレート',
    templateName: 'テンプレート名称',
    txtDetailTemplate: '詳細テンプレート',
    category: '部材種類（用途）',
    description: '備考',
    fieldSet: 'テンプレート項目入力',
    standalone: '標準',
    txtCreateTemplate: 'テンプレートの作成',
    txtEditTemplate: 'テンプレートの編集',
    table: '詳細依頼内容',
    create: '作成する',
    createTemplateSuccess: 'Create Template Success',
    createTemplateFail: 'Create Template Fail',
    txtDocuments: 'ドキュメント',
    descriptionFile: '受け付ける形式: PDF / JPEG / JPG / PNG',
    txtBtnUpload: 'アップロード',
    txtAddNewTemplate: '新規テンプレートの追加',
    txtStandalone: '標準',
    txtTable: '詳細依頼内容',
    txtAddRow: '行を追加する',
    Table: {
      templateID: 'テンプレートID',
      createdDate: '作成日',
      txtDescription: 'Description',
      txtAction: 'Action',
    },
  },
  Invoice: {
    billMonth: '請求月',
    confirmPayment: 'Confirm Payment',
    confirmPaymentSuccess: 'Confirm Payment Success',
    confirmPaymentFail: 'Confirm Payment Fail',
    exportInvoice: '請求書登録（作成）',
    Table: {
      invoiceID: '請求書番号',
      tenantID: 'テナント ID',
      tenants: 'テナント',
      billMonth: '請求月',
      month: '月',
      noOfRequest: 'リクエスト数　',
      pageUsed: '使用したページ',
      blockUsed: '使用ブロック',
      paymentStatus: '支払状態',
      paymentDate: '支払日',
      markAsPaid: '支払済みレ点',
      action: 'アクション',
      usageStatus: '利用状況',
      ocrID: 'OCR ID',
      situation: '状態',
      noOfPage: 'ページ数',
      completedDate: '完了日',
      ocrRate: 'OCR レート',
      uploadDate: '更新日',
      packageUsed: 'Package Used',
      packageRegistered: '登録されたパッケージ',
      blockRegistered: '登録したブロック数',
      actionInvoice: '表示・登録（作成）',
    },
    statusInvoice: {
      processing: '処理中',
      completed: '完了',
      failed: '失敗',
    },
  },
  OCR: {
    ocrTitle: 'OCR書類の申請',
    btnOcrRequest: 'OCR書類の申請',
    warningConfirmation: 'Warning Confirmation Required?',
    descriptionConfir: 'Are you sure you want to submit this?',
    createOcrSuccess: 'Create OCR Success',
    createOcrFail: 'Create OCR Fail',
  },
  AddTenant: {
    emailAddress: 'メールアドレス',
    placeholderPassword:
      '英数字以外の記号、半角英小文字大文字数字をそれぞれ1種類以上含む8文字以上で入力してください',
    companyName: '会社名',
    placeholderCompanyName: 'テナントの会社名を入力してください',
    placeholderEmailAddress: 'メールアドレスを入力してください',
    tenantDescriptions: '備考欄（テナント情報追記）',
    placeholderTenantDescriptions: '説明を入力してください',
    inputCompanyName: 'テナントの会社名を入力してください',
    inputAddress: '住所を入力',
    contactEmail: '連絡先メールアドレス',
    placeholderContactEmail: '連絡先メールアドレスを入力',
    address: '住所',
    placeholderAddress: '住所を入力',
    contactName: '連絡先',
    placeholderContactName: '連絡先を入力してください',
    phoneNumber: '電話番号',
    placeholderPhoneNumber: '電話番号を入力してください',
    blockLimit: 'ブロック制限',
    placeholderBlockLimit: '例: 10',
    expiredDate: '期限切れ',
    tenantStatus: 'テナント状況',
    inputRequired: 'この情報の入力が必要です！',
  },
  OCRList: {
    txtSearch: '検索',
    txtStatus: '状態',
    exportExcelFail: 'Excelのエクスポートに失敗する',
  },

  validateForm: {
    msgRequirePassword: 'この情報の入力が必要です！',
    msgPassError:
      'パスワードは、小文字、大文字、数字、特殊文字を含む少なくとも8文字以上である必要があります！',
    msgNotSamePass: 'The password you just entered does not match',
    msgIsSameOldPass:
      '新しいパスワードは古いパスワードと同じであってはいけません！',
  },
  txtInputNewPasswordRequired: 'この情報の入力が必要です！',
  DocumentManagement: {
    txtPaginationContent: '表示 {{startRange}} - {{endRange}}/{{total}}',
  },
  RuleMessage: {
    inputPassword: 'パスワードを入力してください',
    inputEmail: 'メールアドレスを入力してください',
  },
  Label: {
    labelEmailAddress: 'メールアドレス',
  },

  title: {
    titleAdmin: '管理者',
    titleHome: '家',
    titleNewPassword: '新しいパスワード',
    titleForgotPassword: 'パスワードを忘れた場合',
    titleBilling: '請求',
    titleInvoice: '請求書作成・処理',
  },

  ChangePassWord: {
    currentPassword: '現在のパスワード',
    newPassword: '新しいパスワード',
    confirmPassword: '新しいパスワードを確認',
  },

  Table: {
    tenantID: 'Tenant ID',
    tenant: 'Tenant',
    email: 'メールアドレス',
    blockLimit: 'ブロック制限',
    onboardDate: '登録日付',
    updateDate: '更新日付',
    tenantStatus: 'テナント状況',
    action: 'アクション',
  },
  MessageError: {
    msgSentRequestFail: '電子メール アドレスがシステムに存在しません!',
    loginFail: 'ユーザー名またはパスワードが間違っています!',
    msgSentChangePasswordFail: '現在のパスワードが正しくありません',
    passwordSamePassOld: '新しいパスワードが一致しません!',
    passwordNotMatch: '新しいパスワードが一致しません!',
    valiPassword:
      'パスワードは、小文字、大文字、数字、特殊文字を含む少なくとも8文字以上である必要があります！',
    msgUpdateProfileFail: 'Update profile fail',
    txtFail: '処理に失敗しました！',
    passIncorrect: ' ユーザー名またはパスワードが間違っています!',
    currentCorrect: '現在のパスワードが正しくありません ',
    emailExistsTenant: 'メール アドレスがシステムにすでに存在します。',
    companyExist: '会社名がシステムにすでに存在します。',
    emailDoesNot: '電子メール アドレスがシステムに存在しません!',
  },
  MessageSuccess: {
    msgSentRequestSuccess:
      'パスワードリセットリンクがあなたのメールアドレスに送信されました。パスワードをリセットするには、受信トレイ（または迷惑メールフォルダ）を確認してください。',
    msgUpdateProfileSuccess: 'プロファイルの更新が成功しました',
    passwordResetSuccess: 'パスワードが正常にリセットされました。',
    txtSuccess: '処理が成功しました！',
  },
  Status: {
    Usage: {
      all: 'すべて',
      normal: '適切',
      closeToLimit: '制限に近い',
      fullCapacity: '登録数上限',
    },
    Template: {
      all: 'すべて',
      custom: 'カスタム',
      standard: '標準',
    },
    OCRList: {
      all: 'すべて',
      processing: '処理中',
      completed: '完了',
      failed: '失敗',
    },
    Invoice: {
      all: 'すべて',
      pending: '保留中',
      paid: '支払済み',
      overdue: '期限超過',
    },
    Tenant: {
      all: 'すべて',
      active: '稼働中',
      deactive: '非稼働',
    },
  },
};

export default jp;
