import { DTO } from './base.dto';
import { METHOD } from 'constants/http';
import { ENDPOINT } from 'constants/endpoint';

export interface ILoginBody {
    username: string;
    password: string;
}

export class LoginResponse {
    accessToken: string;
    username: string;
    fullName: string;
    roleName: string;
    constructor(data: { accessToken: string; username: string; fullName: string; roleName: string }) {
        this.accessToken = data.accessToken;
        this.username = data.username;
        this.fullName = data.fullName;
        this.roleName = data.roleName;
    }
}

export class LoginDTO extends DTO {
    public param: undefined;
    public url = ENDPOINT.LOGIN;
    public method = METHOD.POST;
    body: ILoginBody;
    public readonly responseClass = LoginResponse;
    public query: undefined;
    public headers: object;
    constructor(body: ILoginBody) {
        super();
        this.body = body;
        this.headers = { Authorization: undefined };
    }
}
export class GetProfileDTO extends DTO {
    public url = ENDPOINT.USER_ME;
    public method = METHOD.GET;
    public body: undefined;
    public query: undefined;
	public param: undefined;
}
export class LogoutDTO extends DTO {
    public url = ENDPOINT.LOGOUT;
    public method = METHOD.POST;
    public body: undefined;
    public query: undefined;
	public param: undefined;
}