export enum LoadingStatus {
	INIT = 'INIT',
	SUCCESS = 'SUCCESS',
	FAIL = 'FAIL',
	LOADING = 'LOADING',
}
export enum STATUS_CODE {
    SUCCESS = 'SUCCESS',
    FAILED = 'FAILED',
    EMAIL_DOES_NOT_EXISTED = 'EMAIL_DOES_NOT_EXISTED',
    PASSWORD_NOT_MATCH = 'PASSWORD_NOT_MATCH',
    USER_NOT_FOUND = 'USER_NOT_FOUND',
    EMAIL_EXISTED = 'EMAIL_EXISTED',
    BLOCK_ACCOUNT = 'BLOCK_ACCOUNT',
    EMAIL_DOES_NOT_WORK = 'EMAIL_DOES_NOT_WORK',
    TOKEN_NOT_FOUND = 'TOKEN_NOT_FOUND',
    USER_NOT_PERMISSION = 'USER_NOT_PERMISSION',
    OLD_PASSWORD_NOT_MATCH = 'OLD_PASSWORD_NOT_MATCH',
    PASSWORD_VS_CURRENT_PASSWORD = 'PASSWORD_VS_CURRENT_PASSWORD',
    TOKEN_INVALID = 'TOKEN_INVALID',
    TOKEN_EXPIRED = 'TOKEN_EXPIRED',
    VALIDATION_NEW_PASSWORD_AND_OLD_PASSWORD = 'VALIDATION_NEW_PASSWORD_AND_OLD_PASSWORD',
    UNAUTHORIZED = 'UNAUTHORIZED',
    REQUEST_CHANGE_PASSWORD = 'REQUEST_CHANGE_PASSWORD',
    ACCOUNT_NOT_ACTIVATED = 'ACCOUNT_NOT_ACTIVATED',
    NOT_FOUND = 'NOT_FOUND',
    FILE_INVALID = 'Invalid excel file',
    USERNAME_PASSWORD_NOT_MATCH='USERNAME_PASSWORD_NOT_MATCH'
}